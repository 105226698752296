<template>
  <form
    ref="form"
    @submit.prevent="next"
  >
    <c-title>Inhaltliche Angaben zum Vorhaben</c-title>

    <p class="mt-8 mb-8" />
    <c-form-text
      v-model="fields.project_title"
      :errors="errors.project_title"
      class="mb-8"
      label="Titel des Projekts"
      autocomplete="honorific-suffix"
      required
    />
    <c-branch-select
      v-model="fields.branch"
      :errors="errors.branch"
      class="mb-8"
      label="Sparte"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.event_first_day"
      :errors="errors.event_first_day"
      class="mb-8"
      label="Erster Veranstaltungstag"
      help="(nicht Probenbeginn, sondern Ausstellungseröffnung, Premiere o. ä.)"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.duration"
      :errors="errors.duration"
      class="mb-8"
      label="Laufzeit/weitere Termine"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.event_location"
      :errors="errors.event_location"
      class="mb-8"
      label="Veranstaltungsort"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.cooperation_partner"
      :errors="errors.cooperation_partner"
      class="mb-8"
      label="Kooperationspartner*in (optional)"
      autocomplete="honorific-suffix"
    />
    <c-form-text
      v-model="fields.project_desc"
      :errors="errors.project_desc"
      class="mb-8"
      label="Kurzbeschreibung des Vorhabens und Nennung der Projektbeteiligten"
      autocomplete="honorific-suffix"
      required
      tag="textarea"
      maxlength="2500"
      rows="20"
    />
    <c-form-text
      :errors="errors.concept_file"
      class="mt-5 mb-8"
      type="file"
      :file="fields.concept_file"
      label="Konzept"
      :help="`(ausführliche Projektbeschreibung inkl. Kostenplan, Infos zu den Beteiligten und ggf. Arbeitsproben; eine PDF-Datei, beachten Sie unbedingt die Größenbeschränkung: max. 15 Seiten und 5 MB)`"
      accept=".pdf"
      required
      @change="fields.concept_file = $event"
    />
    <c-button
      type="button"
      class="mr-5"
      color="secondary"
      @click="previous"
    >
      Zurück
    </c-button>
    <c-button>
      Nächster Schritt
    </c-button>
  </form>
</template>

<script>
import BRequestStep from './BRequestStep.vue'

export default {
  name: 'VRequestStep1',

  extends: BRequestStep,

  data () {
    return {
      fields: {
        project_title: '',
        branch: '',
        event_first_day: '',
        duration: '',
        event_location: '',
        project_desc: '',
        concept_file: ''
      }
    }
  },

  computed: {
    step: () => 1,
    id: () => 'reportData'
  }
}
</script>
